export const environment = {
  production: true,
  lang: 'en',
  apiEndpoint: 'https://rst-api-uat.allianz.com.my/api/v2',
  ionnexEndpoint: 'https://rst-api-uat.allianz.com.my',
  ionApiKey: 'b2261fdc06639ae3d001dfb55a367dd8',
  ionSecretKey: '6AGb/0pab88umb/aL3c8PT2//KLON9Due/xDpAGAR9U=',
  fixedTenant: 'AZMY',
  keycloak: {
    // url: 'https://auth.imocha.com.my/auth',
    url: "https://azmeet-uat.allianz.com.my/auth",
    realm: 'AZMY',
    clientId: 'rst-bo',
  },
  hideDocumentsTab: false,
  hideaReportTab: true,
  hideBillingReport: true,
  displayManualSignLoc: true,
  displayChannelSelect: false,
  displayFilterByCaseid: false,
  displayCreationDateColumn: false,
  displayTimeFormat: false,
  displayMultipleVideo: false,
  hidePasswordTab: false,
  passwordErrMsg: 'Password must contain at least 10 characters with minimum 1 number, 1 special character, 1 upper case character and 1 lower case character',
  passwordRegex: ".*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*{}()<>?:\"',.\/;~\[\\]`]).{10,}$",
  displayFrVideo: false,
  multipleCasesMenu: true
};